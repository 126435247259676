import React, { useEffect } from "react";
import { loader } from "graphql.macro";
import useDatagrid from "../../hooks/useDatagrid";
import DataGrid from "../DataGrid";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { WS_STATUS } from "../../constants/websocket";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ListIcon from "@mui/icons-material/List";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PopoverImage from "../PopoverImage";

import DvrIcon from "@mui/icons-material/Dvr";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import useFilterUrl from "../../hooks/useFilterUrl";
import Progress from "../Progress";
import stringColumnType from "../DataGrid/stringColumnType";
import Support from "../Roles/Support";
import LanIcon from '@mui/icons-material/Lan';
import NetworkEventsChart from "../NetworkEventsChart";
import FindReplaceIcon from '@mui/icons-material/FindReplace';

const ID = "BoxList";

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

function WsStatusIcon({ status }) {
  if (status === WS_STATUS.CONNECTED) {
    return (
      <Tooltip title={status}>
        <CheckCircleOutlineIcon color="success" fontSize="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={status || ""}>
        <ErrorOutlineIcon color="warning" fontSize="small" />
      </Tooltip>
    );
  }
}

function Tags({ tags }) {
  const { loading, data } = useQuery(TAGS, {
    variables: {
      filter: { id: { in: tags } },
    },
  });
  if (loading) {
    return <Progress />;
  }
  return data.boxTags.nodes.map((tag) => (
    <Tooltip key={tag.id} title={tag.description}>
      <Chip
        size="small"
        variant="outlined"
        sx={{ margin: "10" }}
        label={tag.value}
        color={tag.color}
      />
    </Tooltip>
  ));
}

const isHidden = (field, defaultValue) => {
  let isHidden = defaultValue;
  const hide = window.localStorage.getItem(`column${ID}-${field}`);
  if (hide !== null) {
    isHidden = hide === "true";
  }
  return isHidden;
};

const getWidth = (field, defaultValue) => {
  let width = defaultValue;
  const w = window.localStorage.getItem(`colWidth${ID}-${field}`);
  if (w !== null) {
    width = w;
  }
  return width;
};

function DisconnectProgressCell(props) {
  if (props.value == null) {
    return null;
  }
  const now = new Date();
  const date_updated = new Date(props.value);
  const date_updated_locale = new Date(props.value).toLocaleString("fr-FR", {
    timeZone: "Europe/Paris",
  })

  // Convertir les deux dates en millisecondes
  const time1 = date_updated.getTime();
  const time2 = now.getTime();

  // Calculer la différence en millisecondes
  const differenceInMilliseconds = Math.abs(time2 - time1);

  // Convertir en semaines (1 semaine = 7 jours * 24 heures * 60 minutes * 60 secondes * 1000 millisecondes)
  const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
  const differenceInWeeks = differenceInMilliseconds / millisecondsPerWeek;

  const maxWeeks = 4;
  const percent = differenceInWeeks * 100 / maxWeeks;

  let color = "success"
  if (percent >= 25 && percent < 50) {
    color = "info"
  } else if (percent >= 50 && percent < 75) {
    color = "warning"
  } else if (percent >= 75) {
    color = "error"
  }

  return (
    <Stack direction="row" height="100%" spacing={1} >
      <Tooltip title={date_updated_locale}>
        <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: "center" }}>
          <CircularProgress variant="determinate" value={Math.min(percent, 100)} color={color} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            color={`${color}.main`}
          >
            <Typography
              variant="caption"
              component="div"
            >
              {`${Math.ceil(differenceInWeeks)}`}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
      <Box display="flex" alignItems="center">
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {date_updated_locale}
        </Typography>
      </Box>

    </Stack>
  );
}

const TAGS = loader("../../graphql/boxTags.graphql");

const defaultColumnVisibilityModel = {
  date_created: false,
  configuration_conf: false,
  configuration_freescreenId: false,
  configuration_freescreenServer: false,
  configuration_group: false,
  configuration_isMediaServer: false,
  configuration_orientation: false,
  configuration_resobase: false,
  configuration_roomId: true,
  configuration_scenarioId: false,
  configuration_supervision: false,
  configuration_templateId: false,
  configuration_templateName: false,
  configuration_ocsUrl: false,
  configuration_gitUrl: false,
  configuration_pciNestClientVersion: false,
  configuration_vpTime: false,
  hardware_cpuModel: false,
  hardware_diskUsage: false,
  hardware_ram: false,
  hardware_ssdModel: false,
  hardware_architecture: false,
  hardware_model: false,
  hardware_vpModel: false,
  network_connexion: false,
  network_dns: false,
  network_freescreenServer: false,
  network_gateway: false,
  network_ipPublic: false,
  network_mac: false,
  network_ssid: false,
  network_macGateway: false,
  network_netmask: false,
  network_type: false,
  network_ntpServers: false,
  network_ipVp: false,
  software_chromeVersion: true,
  software_git: false,
  software_httpsAvailable: false,
  software_masterVersion: true,
  software_patchBrand: false,
  software_patchBrandMd5: false,
  software_patchContent: false,
  software_patchContentId: false,
  software_patchContentMd5: false,
  software_patchGeneric: false,
  software_patchGenericMd5: false,
  software_securePassword: false,
  software_teamviewerId: false,
  software_osVersion: false,
  software_gitPlayerVersion: false,
  software_availableCiphers: false,
  supervision_screenshotHash: false,
  custom_details: false,
};

const columns = [
  {
    field: "supervision_wsStatus",
    headerName: "wsStatus",
    get width() {
      return getWidth("supervision_wsStatus", 10);
    },
    get hide() {
      return isHidden("supervision_wsStatus", false);
    },
    renderCell: (params) => (
      <Box px={1}>
        <WsStatusIcon status={params.value} />
      </Box>
    ),
  },
  {
    field: "supervision_screenshotDate",
    headerName: "screenshot",
    get width() {
      return getWidth("supervision_screenshotDate", 150);
    },
    renderCell: (params) => (
      <PopoverImage
        src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_SERVER}/screenshotDds/${params.row["mac"]}?${params.value}`}
        width="100%"
        height="100%"
        popWidth="1366"
        popHeight="768"
      ></PopoverImage>
    ),
  },
  {
    field: "tags.value",
    headerName: "tags",
    get width() {
      return getWidth("tags", 100);
    },
    filterable: true,
    sortable: false,
    get hide() {
      return isHidden("tags", false);
    },
    renderCell: (params) => {
      return (
        <Box px={1}>
          {params.row.tagIds.length > 0 && (
            <Stack direction="row" spacing={1}>
              <Tags tags={params.row.tagIds} />
            </Stack>
          )}
        </Box>
      );
    },
  },
  {
    field: "networkEventsJoined",
    headerName: "Network status",
    get width() {
      return getWidth("networkEventsJoined", 200);
    },
    renderCell: (params) =>
      <NetworkEventsChart data={params.row.networkEventsJoined} width={220} height={115} showLabel={false} />,
    sortable: false,
    filterable: false,
    display: 'flex',
  },
  {
    field: "mac",
    get width() {
      return getWidth("mac", 150);
    },
    get hide() {
      return isHidden("mac", false);
    },
  },
  {
    field: "date_created",
    get width() {
      return getWidth("date_created", 200);
    },
    get hide() {
      return isHidden("date_created", true);
    },
    type: "dateTime",
    valueFormatter: (value) =>
      new Date(value).toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      }),
  },
  {
    field: "date_updated",
    get width() {
      return getWidth("date_updated", 200);
    },
    get hide() {
      return isHidden("date_updated", false);
    },
    type: "dateTime",
    valueFormatter: (value) =>
      value.toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      }),
    valueGetter: (value) => new Date(value),
    renderCell: (params) => <DisconnectProgressCell {...params} />,
  },
  {
    field: "configuration_conf",
    headerName: "conf",
    get width() {
      return getWidth("configuration_conf", 200);
    },
    get hide() {
      return isHidden("configuration_conf", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_freescreenId",
    headerName: "freescreenId",
    get width() {
      return getWidth("configuration_freescreenId", 150);
    },
    get hide() {
      return isHidden("configuration_freescreenId", true);
    },
    type: "number",
  },
  {
    field: "configuration_freescreenServer",
    headerName: "freescreenServer",
    get width() {
      return getWidth("configuration_freescreenServer", 200);
    },
    get hide() {
      return isHidden("configuration_freescreenServer", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_group",
    headerName: "group",
    get width() {
      return getWidth("configuration_group", 100);
    },
    get hide() {
      return isHidden("configuration_group", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_hotel",
    headerName: "hotel",
    get width() {
      return getWidth("configuration_hotel", 150);
    },
    get hide() {
      return isHidden("configuration_hotel", false);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_isMediaServer",
    headerName: "isMediaServer",
    get width() {
      return getWidth("configuration_isMediaServer", 150);
    },
    get hide() {
      return isHidden("configuration_isMediaServer", true);
    },
    type: "boolean",
  },
  {
    field: "configuration_orientation",
    headerName: "orientation",
    get width() {
      return getWidth("configuration_orientation", 150);
    },
    get hide() {
      return isHidden("configuration_orientation", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_resobase",
    headerName: "resobase",
    get width() {
      return getWidth("configuration_resobase", 150);
    },
    get hide() {
      return isHidden("configuration_resobase", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_roomId",
    headerName: "roomId",
    get width() {
      return getWidth("configuration_roomId", 50);
    },
    get hide() {
      return isHidden("configuration_roomId", true);
    },
    type: "number",
  },
  {
    field: "configuration_scenarioId",
    headerName: "scenarioId",
    get width() {
      return getWidth("configuration_scenarioId", 150);
    },
    get hide() {
      return isHidden("configuration_scenarioId", true);
    },
    type: "number",
  },
  {
    field: "configuration_scenarioName",
    headerName: "scenarioName",
    get width() {
      return getWidth("configuration_scenarioName", 200);
    },
    get hide() {
      return isHidden("configuration_scenarioName", false);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_supervision",
    headerName: "supervision",
    get width() {
      return getWidth("configuration_supervision", 200);
    },
    get hide() {
      return isHidden("configuration_supervision", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_templateId",
    headerName: "templateId",
    get width() {
      return getWidth("configuration_templateId", 150);
    },
    get hide() {
      return isHidden("configuration_templateId", true);
    },
    type: "number",
  },
  {
    field: "configuration_templateName",
    headerName: "templateName",
    get width() {
      return getWidth("configuration_templateName", 200);
    },
    get hide() {
      return isHidden("configuration_templateName", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_ocsUrl",
    headerName: "OCS url",
    get width() {
      return getWidth("configuration_ocsUrl", 200);
    },
    get hide() {
      return isHidden("configuration_ocsUrl", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_gitUrl",
    headerName: "GIT url",
    get width() {
      return getWidth("configuration_gitUrl", 200);
    },
    get hide() {
      return isHidden("configuration_gitUrl", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_pciNestClientVersion",
    headerName: "PCI NEST Client Version",
    get width() {
      return getWidth("configuration_pciNestClientVersion", 200);
    },
    get hide() {
      return isHidden("configuration_pciNestClientVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "configuration_vpTime",
    headerName: "VP switch-off time",
    get width() {
      return getWidth("configuration_vpTime", 200);
    },
    get hide() {
      return isHidden("configuration_vpTime", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_cpuModel",
    headerName: "cpuModel",
    get width() {
      return getWidth("hardware_cpuModel", 200);
    },
    get hide() {
      return isHidden("hardware_cpuModel", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_diskUsage",
    headerName: "diskUsage",
    get width() {
      return getWidth("hardware_diskUsage", 150);
    },
    get hide() {
      return isHidden("hardware_diskUsage", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_ram",
    headerName: "ram",
    get width() {
      return getWidth("hardware_ram", 150);
    },
    get hide() {
      return isHidden("hardware_ram", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_ssdModel",
    headerName: "ssdModel",
    get width() {
      return getWidth("hardware_ssdModel", 200);
    },
    get hide() {
      return isHidden("hardware_ssdModel", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_architecture",
    headerName: "architecture",
    get width() {
      return getWidth("hardware_architecture", 100);
    },
    get hide() {
      return isHidden("hardware_architecture", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_model",
    headerName: "model",
    get width() {
      return getWidth("hardware_model", 200);
    },
    get hide() {
      return isHidden("hardware_model", true);
    },
    ...stringColumnType,
  },
  {
    field: "hardware_vpModel",
    headerName: "VP model",
    get width() {
      return getWidth("hardware_vpModel", 200);
    },
    get hide() {
      return isHidden("hardware_vpModel", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_connexion",
    headerName: "connexion",
    get width() {
      return getWidth("network_connexion", 200);
    },
    get hide() {
      return isHidden("network_connexion", true);
    },
    type: "boolean",
  },
  {
    field: "network_dns",
    headerName: "dns",
    get width() {
      return getWidth("network_dns", 150);
    },
    get hide() {
      return isHidden("network_dns", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_freescreenServer",
    headerName: "freescreenServer",
    get width() {
      return getWidth("network_freescreenServer", 150);
    },
    get hide() {
      return isHidden("network_freescreenServer", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_gateway",
    headerName: "gateway",
    get width() {
      return getWidth("network_gateway", 150);
    },
    get hide() {
      return isHidden("network_gateway", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_ip",
    headerName: "ip",
    get width() {
      return getWidth("network_ip", 150);
    },
    get hide() {
      return isHidden("network_ip", false);
    },
    ...stringColumnType,
  },
  {
    field: "network_ipPublic",
    headerName: "ipPublic",
    get width() {
      return getWidth("network_ipPublic", 150);
    },
    get hide() {
      return isHidden("network_ipPublic", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_mac",
    headerName: "mac",
    get width() {
      return getWidth("network_mac", 150);
    },
    get hide() {
      return isHidden("network_mac", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_ssid",
    headerName: "ssid",
    get width() {
      return getWidth("network_ssid", 150);
    },
    get hide() {
      return isHidden("network_ssid", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_macGateway",
    headerName: "macGateway",
    get width() {
      return getWidth("network_macGateway", 150);
    },
    get hide() {
      return isHidden("network_macGateway", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_netmask",
    headerName: "netmask",
    get width() {
      return getWidth("network_netmask", 150);
    },
    get hide() {
      return isHidden("network_netmask", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_type",
    headerName: "network_type",
    get width() {
      return getWidth("network_type", 150);
    },
    get hide() {
      return isHidden("network_type", true);
    },
    ...stringColumnType,
  },
  {
    field: "network_ntpServers",
    headerName: "ntpServers",
    get width() {
      return getWidth("network_ntpServers", 150);
    },
    ...stringColumnType,
  },
  {
    field: "network_ipVp",
    headerName: "IP VP",
    get width() {
      return getWidth("network_ipVp", 150);
    },
    ...stringColumnType,
  },
  {
    field: "software_chromeVersion",
    headerName: "chromeVersion",
    get width() {
      return getWidth("software_chromeVersion", 150);
    },
    get hide() {
      return isHidden("software_chromeVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_git",
    headerName: "git",
    get width() {
      return getWidth("software_git", 150);
    },
    get hide() {
      return isHidden("software_git", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_httpsAvailable",
    headerName: "httpsAvailable",
    get width() {
      return getWidth("software_httpsAvailable", 150);
    },
    get hide() {
      return isHidden("software_httpsAvailable", true);
    },
    type: "boolean",
  },
  {
    field: "software_masterVersion",
    headerName: "masterVersion",
    get width() {
      return getWidth("software_masterVersion", 100);
    },
    get hide() {
      return isHidden("software_masterVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchBrand",
    headerName: "patchBrand",
    get width() {
      return getWidth("software_patchBrand", 200);
    },
    get hide() {
      return isHidden("software_patchBrand", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchBrandMd5",
    headerName: "patchBrandMd5",
    get width() {
      return getWidth("software_patchBrandMd5", 200);
    },
    get hide() {
      return isHidden("software_patchBrandMd5", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchContent",
    headerName: "patchContent",
    get width() {
      return getWidth("software_patchContent", 200);
    },
    get hide() {
      return isHidden("software_patchContent", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchContentId",
    headerName: "patchContentId",
    get width() {
      return getWidth("software_patchContentId", 150);
    },
    get hide() {
      return isHidden("software_patchContentId", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchContentMd5",
    headerName: "patchContentMd5",
    get width() {
      return getWidth("software_patchContentMd5", 200);
    },
    get hide() {
      return isHidden("software_patchContentMd5", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchGeneric",
    headerName: "patchGeneric",
    get width() {
      return getWidth("software_patchGeneric", 200);
    },
    get hide() {
      return isHidden("software_patchGeneric", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_patchGenericMd5",
    headerName: "patchGenericMd5",
    get width() {
      return getWidth("software_patchGenericMd5", 200);
    },
    get hide() {
      return isHidden("software_patchGenericMd5", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_securePassword",
    headerName: "securePassword",
    get width() {
      return getWidth("software_securePassword", 150);
    },
    get hide() {
      return isHidden("software_securePassword", true);
    },
    type: "boolean",
  },
  {
    field: "software_teamviewerId",
    headerName: "teamviewerId",
    get width() {
      return getWidth("software_teamviewerId", 150);
    },
    get hide() {
      return isHidden("software_teamviewerId", true);
    },
    renderCell: (params) => (
      <Link
        target="_blank"
        href={`https://start.teamviewer.com/${params.value}`}
      >
        {params.value}
      </Link>
    ),
    ...stringColumnType,
  },
  {
    field: "software_osVersion",
    headerName: "osVersion",
    get width() {
      return getWidth("software_osVersion", 200);
    },
    get hide() {
      return isHidden("software_osVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_gitPlayerVersion",
    headerName: "gitPlayerVersion",
    get width() {
      return getWidth("software_gitPlayerVersion", 80);
    },
    get hide() {
      return isHidden("software_gitPlayerVersion", true);
    },
    ...stringColumnType,
  },
  {
    field: "software_availableCiphers",
    headerName: "availableCiphers",
    get width() {
      return getWidth("software_availableCiphers", 80);
    },
    get hide() {
      return isHidden("software_availableCiphers", true);
    },
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotHash",
    headerName: "screenshotHash",
    get width() {
      return getWidth("supervision_screenshotHash", 120);
    },
    get hide() {
      return isHidden("supervision_screenshotHash", true);
    },
    ...stringColumnType,
  },
  {
    field: "custom_details",
    headerName: "customDetails",
    get width() {
      return getWidth("custom_details", 300);
    },
    get hide() {
      return isHidden("custom_details", true);
    },
  },
];

const QUERY = loader("../../graphql/boxes.graphql");
const DELETE = loader("../../graphql/deleteOneBox.graphql");

export default function BoxList({ consoleHook, user }) {
  const query = useUrlQuery();
  const { decompress, filterDecompressed } = useFilterUrl();

  const context = useDatagrid({
    id: ID,
    columns,
    defaultColumnVisibilityModel,
  });

  React.useEffect(() => {
    const filter = query.get("filter");
    if (filter && !filterDecompressed) {
      decompress(filter, (result) => {
        context.onFilterChange(result);
      });
    }
  });

  const selectionModel = context.selectionModel;

  let navigate = useNavigate();
  const [deleteOneBox] = useMutation(DELETE);

  function handleOpenConsole() {
    context.selectionModel.forEach((mac) => {
      consoleHook.addConsole(mac);
    });
    navigate(`/consoles/dds`);
  }

  function handleOpenTunnel() {
    context.selectionModel.forEach((mac) => {
      consoleHook.addConsole(mac);
    });
    navigate(`/consoles/dds?scriptId=2&execScriptAll=true`, { state: { macs: context.selectionModel } });
  }

  function handleInventory() {
    context.selectionModel.forEach((mac) => {
      consoleHook.addConsole(mac);
    });
    navigate(`/consoles/dds?scriptId=12&execScriptAll=true`, { state: { macs: context.selectionModel } });
  }

  function handleScreenshot(doRefresh) {
    consoleHook.screenshot(selectionModel, doRefresh);
  }

  function handleDelete() {
    context.selectionModel.forEach((id) => {
      deleteOneBox({
        variables: {
          input: {
            id,
          },
        },
      });
    });
    context.setSelectionModel([]);
  }


  return (
    <Box display="flex" height="85vh">
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          columns={columns}
          context={context}
          query={QUERY}
          checkboxSelection
          quickSearchColumn="configuration_hotel"
          quickSearchColumnLabel="hotel"
          onCellClick={(params, event) => {
            if (params.field === "supervision_screenshotDate") {
              event.defaultMuiPrevented = true;
              event.stopPropagation();
            }
          }}
        />
        <ButtonGroup color="primary">
          <Tooltip title="Box Details">
            <span>
              <Button
                onClick={() =>
                  navigate("/boxes/details", {
                    state: { boxIds: selectionModel },
                  })
                }
                disabled={selectionModel.length === 0}
              >
                <ListIcon />
              </Button>
            </span>
          </Tooltip>
          <Support user={user}>
            <Tooltip title="Open console">
              <span>
                <Button
                  onClick={() => handleOpenConsole()}
                  disabled={selectionModel.length === 0}
                >
                  <DvrIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          <Support user={user}>
            <Tooltip title="Open tunnel">
              <span>
                <Button
                  onClick={() => handleOpenTunnel()}
                  disabled={selectionModel.length === 0}
                >
                  <LanIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          <Support user={user}>
            <Tooltip title="Launch inventory">
              <span>
                <Button
                  onClick={() => handleInventory()}
                  disabled={selectionModel.length === 0}
                >
                  <FindReplaceIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          <Tooltip title="Take screenshot">
            <span>
              <Button
                onClick={() => handleScreenshot(false)}
                disabled={selectionModel.length === 0}
              >
                <WallpaperIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Refresh + screenshot">
            <span>
              <Button
                onClick={() => handleScreenshot(true)}
                disabled={selectionModel.length === 0}
              >
                <RefreshIcon />
                <WallpaperIcon />
              </Button>
            </span>
          </Tooltip>
          <Support user={user}>
            <Tooltip title="Delete">
              <span>
                <Button
                  onClick={() => handleDelete()}
                  disabled={selectionModel.length === 0}
                >
                  <DeleteIcon />
                </Button>
              </span>
            </Tooltip>
          </Support>
          <Tooltip title="Screenshot view">
            <span>
              <Button
                onClick={() =>
                  navigate(`/screenshots/boxes`, {
                    state: {
                      filter:
                        selectionModel.length === 0
                          ? context.filter
                          : {
                            mac: {
                              in: selectionModel,
                            },
                          },
                      paging: context.paging,
                      sorting: context.sorting,
                    },
                  })
                }
              >
                <InsertPhotoIcon />
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
      </div>
    </Box>
  );
}

export { columns };
