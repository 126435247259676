import React, { useEffect, useState } from "react";
import { loader } from "graphql.macro";
import useDatagrid from "../../hooks/useDatagrid";
import DataGrid from "../DataGrid";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  /*Chip,*/ Link,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useMutation, /*useQuery*/
  useQuery
} from "@apollo/client";
import { WS_STATUS } from "../../constants/websocket";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ListIcon from "@mui/icons-material/List";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import DvrIcon from "@mui/icons-material/Dvr";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
//import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
//import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import useFilterUrl from "../../hooks/useFilterUrl";
//import Progress from "../Progress";
import PopoverImage from "../PopoverImage";
import HearingIcon from "@mui/icons-material/Hearing";
import HearingDisabledIcon from "@mui/icons-material/HearingDisabled";
import NoiseAwareIcon from "@mui/icons-material/NoiseAware";
import stringColumnType from "../DataGrid/stringColumnType";
import Support from "../Roles/Support";
import LanIcon from '@mui/icons-material/Lan';
import { LineChart, ScatterChart } from "@mui/x-charts";
import NetworkEventsChart from "../NetworkEventsChart";
import Progress from "../Progress";

const ID = "PlayerList";

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

function WsStatusIcon({ status }) {
  if (status === WS_STATUS.CONNECTED) {
    return (
      <Tooltip title={status}>
        <CheckCircleOutlineIcon color="success" fontSize="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={status || ""}>
        <ErrorOutlineIcon color="warning" fontSize="small" />
      </Tooltip>
    );
  }
}

function SoundStatusIcon({ status }) {
  if (status === true) {
    return (
      <Tooltip title="Playing">
        <HearingIcon color="success" fontSize="small" />
      </Tooltip>
    );
  } else if (status === false) {
    return (
      <Tooltip title="Not playing">
        <HearingDisabledIcon color="error" fontSize="small" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Unknown">
        <NoiseAwareIcon color="warning" fontSize="small" />
      </Tooltip>
    );
  }
}

function DisconnectProgressCell(props) {
  if (props.value == null) {
    return null;
  }
  const now = new Date();
  const date_updated = new Date(props.value);
  const date_updated_locale = new Date(props.value).toLocaleString("fr-FR", {
    timeZone: "Europe/Paris",
  })

  // Convertir les deux dates en millisecondes
  const time1 = date_updated.getTime();
  const time2 = now.getTime();

  // Calculer la différence en millisecondes
  const differenceInMilliseconds = Math.abs(time2 - time1);

  // Convertir en semaines (1 semaine = 7 jours * 24 heures * 60 minutes * 60 secondes * 1000 millisecondes)
  const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
  const differenceInWeeks = differenceInMilliseconds / millisecondsPerWeek;

  const maxWeeks = 4;
  const percent = differenceInWeeks * 100 / maxWeeks;

  let color = "success"
  if (percent >= 25 && percent < 50) {
    color = "info"
  } else if (percent >= 50 && percent < 75) {
    color = "warning"
  } else if (percent >= 75) {
    color = "error"
  }

  return (
    <Stack direction="row" height="100%" spacing={1} >
      <Tooltip title={date_updated_locale} placement="right">
        <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: "center" }}>
          <CircularProgress variant="determinate" value={Math.min(percent, 100)} color={color} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            color={`${color}.main`}
          >
            <Typography
              variant="caption"
              component="div"
            >
              {`${Math.ceil(differenceInWeeks)}`}
            </Typography>
          </Box>
        </Box>
      </Tooltip>
      <Box display="flex" alignItems="center">
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {date_updated_locale}
        </Typography>
      </Box>

    </Stack>
  );
}

function Tags({ tags }) {
  const { loading, data } = useQuery(TAGS, {
    variables: {
      filter: { id: { in: tags } },
    },
  });
  if (loading) {
    return <Progress />;
  }
  return data.playerTags.nodes.map((tag) => (
    <Tooltip title={tag.description}>
      <Chip
        key={tag.id}
        size="small"
        variant="outlined"
        sx={{ margin: "10" }}
        label={tag.value}
        color={tag.color}
      />
    </Tooltip>
  ));
}

const isHidden = (field, defaultValue) => {
  let isHidden = defaultValue;
  const hide = window.localStorage.getItem(`column${ID}-${field}`);
  if (hide !== null) {
    isHidden = hide === "true";
  }
  return isHidden;
};

const getWidth = (field, defaultValue) => {
  let width = defaultValue;
  const w = window.localStorage.getItem(`colWidth${ID}-${field}`);
  if (w !== null) {
    width = w;
  }
  return width;
};

const TAGS = loader("../../graphql/playerTags.graphql");

function getPlayerConfig(row, field) {
  return row.playerConfig ? row.playerConfig[field] : null;
}

const defaultColumnVisibilityModel = {
  ipPublic: true,
  date_created: false,
  networkEvents: true,
  temp: false,
  downloadError: false,
  gitPullError: false,
  ramTotal: false,
  "playerplayerConfig.mac": false,
  "playerConfig.mp3Folder": false,
  "playerConfig.mp3JukeBox": false,
  "playerConfig.mp3JukeBoxKeep": false,
  "playerConfig.mp3JukeBoxCustom": false,
  "playerConfig.mongoUrl": false,
  "playerConfig.rsyncUrl": false,
  "playerConfig.syncListUrl": false,
  "playerConfig.supervision": false,
  "playerConfig.planningUrl": false,
  "playerConfig.country": false,
  "playerConfig.zone": false,
  "playerConfig.mac": false,
  "playerConfig.validated": false,
  "playerConfig.tvId": true,
  "playerConfig.pathApp": false,
  "playerConfig.ddpURL": false,
  "playerConfig.ddpPort": false,
  "playerConfig.loginUrl": false,
  "playerConfig.downloadUrl": false,
  "playerConfig.volume": false,
  "playerConfig.dlMethod": false,
  "playerConfig.pciUrl": false,
  "playerConfig.database": false,
  supervision_screenshotHash: false,
  pciNestClientVersion: false,
  nodeVersion: false,
  systemVolume: false,
};

const defaultColumns = [
  {
    field: "supervision_wsStatus",
    headerName: "wsStatus",
    get width() {
      return getWidth("supervision_wsStatus", 10);
    },
    renderCell: (params) => (
      <Box px={1}>
        <WsStatusIcon status={params.value} />
      </Box>
    ),
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotDate",
    headerName: "screenshot",
    get width() {
      return getWidth("supervision_screenshotDate", 150);
    },
    renderCell: (params) => (
      <PopoverImage
        src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_SERVER}/screenshotMelodjy/${params.row["mac"]}?${params.value}`}
        width="100%"
        height="100%"
        popWidth="1366"
        popHeight="768"
      ></PopoverImage>
    ),
  },
  {
    field: "tags.value",
    headerName: "tags",
    get width() {
      return getWidth("tags", 100);
    },
    filterable: true,
    sortable: false,
    get hide() {
      return isHidden("tags", false);
    },
    renderCell: (params) => {
      return (
        <Box px={1}>
          {params.row.tagIds.length > 0 && (
            <Stack direction="row" spacing={1}>
              <Tags tags={params.row.tagIds} />
            </Stack>
          )}
        </Box>
      );
    },
  },
  {
    field: "supervision_soundRunning",
    headerName: "playing",
    type: "boolean",
    get width() {
      return getWidth("supervision_soundRunning", 10);
    },
    renderCell: (params) => (
      <Box px={1}>
        <SoundStatusIcon status={params.value} />
      </Box>
    ),
    ...stringColumnType,
  },
  {
    field: "networkEventsJoined",
    headerName: "Network status",
    get width() {
      return getWidth("networkEventsJoined", 200);
    },
    renderCell: (params) =>
      <NetworkEventsChart data={params.row.networkEventsJoined} width={220} height={115} showLabel={false} />,
    sortable: false,
    filterable: false,
    display: 'flex',
  },
  {
    field: "date_updated",
    get width() {
      return getWidth("date_updated", 200);
    },
    valueFormatter: (value) =>
      value.toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      }),
    valueGetter: (value) => new Date(value),
    renderCell: (params) => <DisconnectProgressCell {...params} />,
    type: "dateTime",
  },
  {
    field: "mac",
    get width() {
      return getWidth("mac", 150);
    },
    ...stringColumnType,
  },
  {
    field: "date_created",
    get width() {
      return getWidth("date_created", 150);
    },
    valueGetter: (value) => new Date(value),
    type: "date",
  },
  {
    field: "planning",
    get width() {
      return getWidth("planning", 150);
    },
    ...stringColumnType,
  },
  {
    field: "playlist",
    get width() {
      return getWidth("playlist", 150);
    },
    ...stringColumnType,
  },
  {
    field: "ipPublic",
    get width() {
      return getWidth("ipPublic", 150);
    },
    ...stringColumnType,
  },
  {
    field: "usedSpaceSystem",
    get width() {
      return getWidth("usedSpaceSystem", 100);
    },
    ...stringColumnType,
  },
  {
    field: "usedSpaceData",
    get width() {
      return getWidth("usedSpaceData", 100);
    },
    ...stringColumnType,
  },
  {
    field: "temp",
    get width() {
      return getWidth("temp", 150);
    },
    ...stringColumnType,
  },
  {
    field: "playerVersion",
    get width() {
      return getWidth("playerVersion", 100);
    },
    ...stringColumnType,
  },
  {
    field: "downloadError",
    get width() {
      return getWidth("downloadError", 150);
    },
    ...stringColumnType,
  },
  {
    field: "gitPullError",
    get width() {
      return getWidth("gitPullError", 150);
    },
    ...stringColumnType,
  },
  {
    field: "ramTotal",
    get width() {
      return getWidth("ramTotal", 150);
    },
    ...stringColumnType,
  },
  {
    field: "systemVolume",
    get width() {
      return getWidth("systemVolume", 150);
    },
    ...stringColumnType,
  },
  {
    field: "playerConfig.mac",
    get width() {
      return getWidth("playerConfig.mac", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "mac"),
    sortable: false,
  },
  {
    field: "playerConfig.mp3Folder",
    get width() {
      return getWidth("playerConfig.mp3Folder", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "mp3Folder"),
    sortable: false,
  },
  {
    field: "playerConfig.mp3JukeBox",
    get width() {
      return getWidth("playerConfig.mp3JukeBox", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "mp3JukeBox"),
    sortable: false,
  },
  {
    field: "playerConfig.mp3JukeBoxKeep",
    get width() {
      return getWidth("playerConfig.mp3JukeBoxKeep", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "mp3JukeBoxKeep"),
    sortable: false,
  },
  {
    field: "playerConfig.mp3JukeBoxCustom",
    get width() {
      return getWidth("playerConfig.mp3JukeBoxCustom", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "mp3JukeBoxCustom"),
    sortable: false,
  },
  {
    field: "playerConfig.mongoUrl",
    get width() {
      return getWidth("playerConfig.mongoUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "mongoUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.rsyncUrl",
    get width() {
      return getWidth("playerConfig.rsyncUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "rsyncUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.syncListUrl",
    get width() {
      return getWidth("playerConfig.syncListUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "syncListUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.supervision",
    get width() {
      return getWidth("playerConfig.supervision", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "supervision"),
    sortable: false,
  },
  {
    field: "playerConfig.planningUrl",
    get width() {
      return getWidth("playerConfig.planningUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "planningUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.country",
    get width() {
      return getWidth("playerConfig.country", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "country"),
    sortable: false,
  },
  {
    field: "playerConfig.zone",
    get width() {
      return getWidth("playerConfig.zone", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "zone"),
    sortable: false,
  },
  {
    field: "playerConfig.hardware",
    get width() {
      return getWidth("playerConfig.hardware", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "hardware"),
    sortable: false,
  },
  {
    field: "playerConfig.siteCode",
    get width() {
      return getWidth("playerConfig.siteCode", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "siteCode"),
    sortable: false,
  },
  {
    field: "playerConfig.ip",
    get width() {
      return getWidth("playerConfig.ip", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "ip"),
    sortable: false,
  },
  {
    field: "playerConfig.validated",
    get width() {
      return getWidth("playerConfig.validated", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "validated"),
    sortable: false,
  },
  {
    field: "playerConfig.tvId",
    get width() {
      return getWidth("playerConfig.tvId", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "tvId"),
    renderCell: (params) => (
      <Link
        target="_blank"
        href={`https://start.teamviewer.com/${params.value}`}
      >
        {params.value}
      </Link>
    ),
    sortable: false,
  },
  {
    field: "playerConfig.pathApp",
    get width() {
      return getWidth("playerConfig.pathApp", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "pathApp"),
    sortable: false,
  },
  {
    field: "playerConfig.ddpURL",
    get width() {
      return getWidth("playerConfig.ddpURL", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "ddpURL"),
    sortable: false,
  },
  {
    field: "playerConfig.ddpPort",
    get width() {
      return getWidth("playerConfig.ddpPort", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "ddpPort"),
    sortable: false,
  },
  {
    field: "playerConfig.loginUrl",
    get width() {
      return getWidth("playerConfig.loginUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "loginUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.downloadUrl",
    get width() {
      return getWidth("playerConfig.downloadUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "downloadUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.offer",
    get width() {
      return getWidth("playerConfig.offer", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "offer"),
    sortable: false,
  },
  {
    field: "playerConfig.volume",
    get width() {
      return getWidth("playerConfig.volume", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "volume"),
    sortable: false,
  },
  {
    field: "playerConfig.dlMethod",
    get width() {
      return getWidth("playerConfig.dlMethod", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "dlMethod"),
    sortable: false,
  },
  {
    field: "playerConfig.pciUrl",
    get width() {
      return getWidth("playerConfig.pciUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "pciUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.database",
    get width() {
      return getWidth("playerConfig.database", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "database"),
    sortable: false,
    ...stringColumnType,
  },
  {
    field: "pciNestClientVersion",
    headerName: "PCI client version",
    get width() {
      return getWidth("pciNestClientVersion", 100);
    },
    ...stringColumnType,
  },
  {
    field: "nodeVersion",
    headerName: "Node version",
    get width() {
      return getWidth("nodeVersion", 100);
    },
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotHash",
    headerName: "screenshotHash",
    get width() {
      return getWidth("supervision_screenshotHash", 120);
    },
    ...stringColumnType,
  },
];

const vaultColumns = [
  {
    field: "supervision_wsStatus",
    headerName: "wsStatus",
    get width() {
      return getWidth("supervision_wsStatus", 10);
    },
    renderCell: (params) => (
      <Box px={1}>
        <WsStatusIcon status={params.value} />
      </Box>
    ),
    ...stringColumnType,
  },
  {
    field: "supervision_soundRunning",
    headerName: "playing",
    type: "boolean",
    get width() {
      return getWidth("supervision_soundRunning", 10);
    },
    renderCell: (params) => (
      <Box px={1}>
        <SoundStatusIcon status={params.value} />
      </Box>
    ),
    ...stringColumnType,
  },
  {
    field: "date_updated",
    get width() {
      return getWidth("date_updated", 200);
    },
    valueFormatter: (value) =>
      value.toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      }),
    valueGetter: (value) => new Date(value),
    renderCell: (params) => <DisconnectProgressCell {...params} />,
    type: "dateTime",
  },
  {
    field: "mac",
    get width() {
      return getWidth("mac", 150);
    },
    ...stringColumnType,
  },
  {
    field: "date_created",
    get width() {
      return getWidth("date_created", 150);
    },
    valueGetter: (value) => new Date(value),
    type: "date",
  },
  {
    field: "planning",
    get width() {
      return getWidth("planning", 150);
    },
    ...stringColumnType,
  },
  {
    field: "playlist",
    get width() {
      return getWidth("playlist", 150);
    },
    ...stringColumnType,
  },
  {
    field: "ipPublic",
    get width() {
      return getWidth("ipPublic", 150);
    },
    ...stringColumnType,
  },
  {
    field: "usedSpaceSystem",
    get width() {
      return getWidth("usedSpaceSystem", 100);
    },
    ...stringColumnType,
  },
  {
    field: "usedSpaceData",
    get width() {
      return getWidth("usedSpaceData", 100);
    },
    ...stringColumnType,
  },
  {
    field: "temp",
    get width() {
      return getWidth("temp", 150);
    },
    ...stringColumnType,
  },
  {
    field: "playerVersion",
    get width() {
      return getWidth("playerVersion", 100);
    },
    ...stringColumnType,
  },
  {
    field: "downloadError",
    get width() {
      return getWidth("downloadError", 150);
    },
    ...stringColumnType,
  },
  {
    field: "gitPullError",
    get width() {
      return getWidth("gitPullError", 150);
    },
    ...stringColumnType,
  },
  {
    field: "ramTotal",
    get width() {
      return getWidth("ramTotal", 150);
    },
    ...stringColumnType,
  },
  {
    field: "systemVolume",
    get width() {
      return getWidth("systemVolume", 150);
    },
    ...stringColumnType,
  },
  {
    field: "playerConfig.mac",
    get width() {
      return getWidth("playerConfig.mac", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "mac"),
    sortable: false,
  },
  {
    field: "playerConfig.supervision",
    get width() {
      return getWidth("playerConfig.supervision", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "supervision"),
    sortable: false,
  },
  {
    field: "playerConfig.zone",
    get width() {
      return getWidth("playerConfig.zone", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "zone"),
    sortable: false,
  },
  {
    field: "playerConfig.hardware",
    get width() {
      return getWidth("playerConfig.hardware", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "hardware"),
    sortable: false,
  },
  {
    field: "playerConfig.siteCode",
    get width() {
      return getWidth("playerConfig.siteCode", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "siteCode"),
    sortable: false,
  },
  {
    field: "playerConfig.ip",
    get width() {
      return getWidth("playerConfig.ip", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "ip"),
    sortable: false,
  },
  {
    field: "playerConfig.validated",
    get width() {
      return getWidth("playerConfig.validated", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "validated"),
    sortable: false,
  },
  {
    field: "playerConfig.ddpURL",
    get width() {
      return getWidth("playerConfig.ddpURL", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "ddpURL"),
    sortable: false,
  },
  {
    field: "playerConfig.ddpPort",
    get width() {
      return getWidth("playerConfig.ddpPort", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "ddpPort"),
    sortable: false,
  },
  {
    field: "playerConfig.loginUrl",
    get width() {
      return getWidth("playerConfig.loginUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "loginUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.downloadUrl",
    get width() {
      return getWidth("playerConfig.downloadUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "downloadUrl"),
    sortable: false,
  },
  {
    field: "playerConfig.offer",
    get width() {
      return getWidth("playerConfig.offer", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "offer"),
    sortable: false,
  },
  {
    field: "playerConfig.pciUrl",
    get width() {
      return getWidth("playerConfig.pciUrl", 150);
    },
    valueGetter: (value, row) => getPlayerConfig(row, "pciUrl"),
    sortable: false,
  },
  {
    field: "pciNestClientVersion",
    headerName: "PCI client version",
    get width() {
      return getWidth("pciNestClientVersion", 100);
    },
    ...stringColumnType,
  },
  {
    field: "nodeVersion",
    headerName: "Node version",
    get width() {
      return getWidth("nodeVersion", 100);
    },
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotHash",
    headerName: "screenshotHash",
    get width() {
      return getWidth("supervision_screenshotHash", 120);
    },
    ...stringColumnType,
  },
  {
    field: "supervision_screenshotDate",
    headerName: "screenshot",
    get width() {
      return getWidth("supervision_screenshotDate", 150);
    },
    renderCell: (params) => (
      <PopoverImage
        src={`${window.__RUNTIME_CONFIG__.REACT_APP_API_SERVER}/screenshotMelodjy/${params.row["mac"]}?${params.value}`}
        width="100%"
        height="100%"
        popWidth="1366"
        popHeight="768"
      ></PopoverImage>
    ),
  },
];

const QUERY = loader("../../graphql/players.graphql");
const DELETE = loader("../../graphql/deleteOnePlayer.graphql");

const columnVisibilityModes = {
  standard: 0,
  vault: 1,
}

export default function PlayerList({ consoleHook, user }) {
  const query = useUrlQuery();
  const { decompress, filterDecompressed } = useFilterUrl();
  const [columnVisibilityMode, setColumnVisibilityMode] = useState(columnVisibilityModes.standard);

  const columns = React.useMemo(() => {
    switch (columnVisibilityMode) {
      default:
      case columnVisibilityModes.standard:
        return defaultColumns;
      case columnVisibilityModes.vault:
        return vaultColumns;
    }
  }, [columnVisibilityMode]);

  const context = useDatagrid({
    id: ID,
    columns,
    defaultColumnVisibilityModel,
  });

  React.useEffect(() => {
    const filter = query.get("filter");
    if (filter && !filterDecompressed) {
      decompress(filter, (result) => {
        context.onFilterChange(result);
      });
    }
  });

  const selectionModel = context.selectionModel;

  let navigate = useNavigate();
  const [deleteOnePlayer] = useMutation(DELETE);

  function handleOpenConsole() {
    context.selectionModel.forEach((mac) => {
      consoleHook.addConsole(mac);
    });
    navigate(`/consoles/melodjy`);
  }

  function handleOpenTunnel() {
    context.selectionModel.forEach((mac) => {
      consoleHook.addConsole(mac);
    });
    navigate(`/consoles/melodjy?scriptId=2&execScriptAll=true`, { state: { macs: context.selectionModel } });
  }

  function handleScreenshot(doRefresh) {
    consoleHook.screenshot(selectionModel, doRefresh);
  }

  function handleDelete() {
    context.selectionModel.forEach((id) => {
      deleteOnePlayer({
        variables: {
          input: {
            id,
          },
        },
      });
    });
    context.setSelectionModel([]);
  }

  return (
    <Box>
      <Box display="flex" height="85vh">
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            key={columnVisibilityMode}
            columns={columns}
            context={context}
            query={QUERY}
            checkboxSelection
            quickSearchColumn="playerConfig.siteCode"
            quickSearchColumnLabel="hotel"
            onCellClick={(params, event) => {
              if (params.field === "supervision_screenshotDate") {
                event.defaultMuiPrevented = true;
                event.stopPropagation();
              }
            }}
          />
          <Stack direction="row" spacing={1}>
            <ButtonGroup color="primary">
              {
                <Tooltip title="Player Details">
                  <Button
                    onClick={() =>
                      navigate("/players/details", {
                        state: { playerIds: selectionModel },
                      })
                    }
                    disabled={selectionModel.length === 0}
                  >
                    <ListIcon />
                  </Button>
                </Tooltip>
              }
              <Support user={user}>
                <Tooltip title="Open console">
                  <Button
                    onClick={() => handleOpenConsole()}
                    disabled={selectionModel.length === 0}
                  >
                    <DvrIcon />
                  </Button>
                </Tooltip>
              </Support>
              <Support user={user}>
                <Tooltip title="Open tunnel">
                  <Button
                    onClick={() => handleOpenTunnel()}
                    disabled={selectionModel.length === 0}
                  >
                    <LanIcon />
                  </Button>
                </Tooltip>
              </Support>
              <Tooltip title="Take screenshot">
                <Button
                  onClick={() => handleScreenshot(false)}
                  disabled={selectionModel.length === 0}
                >
                  <WallpaperIcon />
                </Button>
              </Tooltip>
              <Support user={user}>
                <Tooltip title="Delete">
                  <Button
                    onClick={() => handleDelete()}
                    disabled={selectionModel.length === 0}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </Support>
            </ButtonGroup>
            <Box sx={{ width: 200 }}>
              <FormControl margin="dense" fullWidth size="small">
                <InputLabel>Column visibility mode</InputLabel>
                <Select
                  value={columnVisibilityMode}
                  label="Column visibility mode"
                  onChange={(e) => setColumnVisibilityMode(e.target.value)}
                  sx={{ marginTop: 1 }}
                >
                  <MenuItem value={columnVisibilityModes.standard}>Standard</MenuItem>
                  <MenuItem value={columnVisibilityModes.vault}>Vault</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </div>
      </Box>
    </Box>
  );
}
