import { LineChart } from "@mui/x-charts";

export default function NetworkEventsChart({ data, width, height, showLabel = true }) {
    return (
        <LineChart
            desc="NetworkEvents"
            width={width}
            height={height}
            dataset={[{ date: new Date(), connected: data?.[0]?.connected }, ...data].map((node) => {
                return {
                    date: new Date(node.date),
                    connected: node.connected ? 1 : 0,
                };
            }).reverse()}
            xAxis={[{
                dataKey: 'date',
                scaleType: "utc",
                valueFormatter: (v, context) => context.location === 'tick' ? v.toLocaleTimeString() : v.toLocaleString(),
            }]}
            leftAxis={null}
            series={[
                {
                    dataKey: 'connected',
                    curve: 'stepAfter',
                    valueFormatter: (v) => v === 1 ? 'Connected' : 'Disconnected',
                    area: true,
                    showMark: false,
                    label: showLabel ? 'Network status' : undefined,
                },
            ]}
        />)
}