import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import { Box, Button, IconButton, TextField } from "@mui/material";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { useSnackbar } from "notistack";
import stringColumnType from "./stringColumnType";
import GraphqlError from "../GraphqlError";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

LicenseInfo.setLicenseKey(
  "3456675ff09ba5a15bc6f616fbb0bd06Tz0xMDIyODYsRT0xNzYzODk0MDE5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

function CustomToolbarUncheck() {
  const { current } = useGridApiContext();

  const clearSelectedRows = () => {
    current.setSelectionModel([]);
  };

  return (
    <Button
      onClick={() => clearSelectedRows()}
      size="small"
      startIcon={<IndeterminateCheckBoxOutlinedIcon />}
    >
      Unselect all
    </Button>
  );
}

function CustomToolbarShare({ shareFilter }) {
  const { enqueueSnackbar } = useSnackbar();

  const url = `${window.location.origin}${window.location.pathname}?filter=`;

  const handleFilterCompressed = async (compressedFilter) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(`${url}${compressedFilter}`);
    } else {
      document.execCommand("copy", true, `${url}${compressedFilter}`);
    }
    enqueueSnackbar("URL copied to clipboard");
  };

  return (
    <Button
      onClick={() => shareFilter(handleFilterCompressed)}
      size="small"
      startIcon={<ShareIcon />}
    >
      Share
    </Button>
  );
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder={`Search ${props.columnLabel || props.column} ...`}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}

function CustomToolbar({
  shareFilter,
  quickSearchColumn,
  quickSearchColumnLabel,
  quickSearchValue,
  onQuickSearchChange,
  onQuickSearchClear,
}) {
  return (
    <GridToolbarContainer>
      {quickSearchColumn && (
        <QuickSearchToolbar
          value={quickSearchValue}
          onChange={onQuickSearchChange}
          clearSearch={onQuickSearchClear}
          column={quickSearchColumn}
          columnLabel={quickSearchColumnLabel}
        />
      )}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <CustomToolbarUncheck />
      <CustomToolbarShare shareFilter={shareFilter} />
    </GridToolbarContainer>
  );
}

export default function DataGrid({
  columns,
  query,
  context,
  onColumnVisibilityChange,
  quickSearchColumn,
  quickSearchColumnLabel,
  ...others
}) {
  const [quickSearchValue, setQuickSearchValue] = useState();
  const [orderedColumns, setOrderedColumns] = useState(columns);
  const { loading, error, data } = useQuery(query, {
    pollInterval:
      context.paging.limit <= 1000
        ? parseInt(window.__RUNTIME_CONFIG__.REACT_APP_POLL_INTERVAL)
        : 0, // disable polling for more than 1000 rows
    variables: {
      paging: context.paging,
      sorting: context.sorting,
      filter: context.filter,
    },
  });
  const apiRef = useGridApiRef();

  /*
  if (error) {
    return <GraphqlError error={error} />;
  }
  */

  function onQuickSearchChange(event) {
    setQuickSearchValue(event.target.value);
    context.onFilterChange({
      items: [
        {
          field: quickSearchColumn,
          operator: "contains",
          value: event.target.value,
        },
      ],
      logicOperator: "and",
    });
  }

  function onQuickSearchClear() {
    setQuickSearchValue("");
    context.onFilterChange({
      items: [
        {
          field: "configuration_hotel",
          operator: "contains",
        },
      ],
      logicOperator: "and",
    });
  }

  useEffect(() => {
    const savedColumnOrder = context.columnOrder;
    if (savedColumnOrder) {
      // Réorganiser les colonnes selon l'ordre dans localStorage
      const cols = savedColumnOrder
        .map((field) => columns.find((col) => col.field === field))
        .filter(Boolean); // Filtrer les colonnes valides
      setOrderedColumns(cols);
    }
  }, [columns, context.columnOrder]);

  return (
    <>
      {error && <GraphqlError error={error} />}
      <DataGridPro
        apiRef={apiRef}
        rows={
          loading || !data
            ? []
            : data[query.definitions[0].name.value].nodes.map((box) => {
              return { id: box.mac, ...box };
            })
        }
        loading={loading}
        rowCount={
          loading || !data ? 0 : data[query.definitions[0].name.value].totalCount
        }
        columns={orderedColumns}
        columnVisibilityModel={context.columnVisibilityModel}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            shareFilter: context.shareFilter,
            quickSearchValue,
            onQuickSearchChange,
            onQuickSearchClear,
            quickSearchColumn,
            quickSearchColumnLabel,
          },
        }}
        paginationModel={{ page: context.page, pageSize: context.pageSize }}
        onPaginationModelChange={context.handlePaginationModelChange}
        pageSizeOptions={context.rowsPerPageOptions}
        pagination
        paginationMode="server"
        sortingMode="server"
        sortModel={context.sortModel}
        onSortModelChange={context.setSortModel}
        filterModel={context.filterValue}
        filterMode="server"
        onFilterModelChange={context.onFilterChange}
        rowSelectionModel={context.selectionModel}
        onRowSelectionModelChange={(newSelection) => {
          context.setSelectionModel(newSelection);
        }}
        onColumnVisibilityModelChange={(newModel) =>
          context.onColumnVisibilityModelChange(newModel)
        }
        onColumnWidthChange={context.onColumnWidthChange}
        onColumnOrderChange={(params) => context.onColumnOrderChange(apiRef, params)}
        {...others}
      />
    </>
  );
}
